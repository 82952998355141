import React from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WarnTriangleIcon from '@mui/icons-material/Warning';
import { WarnCircleIcon } from '@theme/icons';
import Button from '@mui/material/Button';
import NextLink from 'next/link';
import styles from './AlertBlock.module.scss';
import { AlertBlockMode, AlertBlockVariant } from './AlertBlock.enum';
import { AlertBlockProps } from './AlertBlock.interface';
import cn from 'classnames';

const AlertBlock: React.FC<AlertBlockProps> = ({ onClose, title, description, buttonText, link, variant, mode }) => {
  const icon =
    variant === AlertBlockVariant.Error ? (
      <WarnTriangleIcon className='icon' />
    ) : variant === AlertBlockVariant.Warning ? (
      <WarnTriangleIcon className='icon' />
    ) : variant === AlertBlockVariant.Success ? (
      <WarnCircleIcon className='icon' />
    ) : null;

  return (
    <div className={cn(styles.alertBlock, variant && styles[`${variant}Block`], mode && styles[`${mode}Mode`])}>
      {onClose && (
        <IconButton className={styles.errorBlockClose} onClick={onClose}>
          <CloseIcon fontSize='inherit' />
        </IconButton>
      )}
      {mode === AlertBlockMode.None && icon}
      <div className={cn(styles.alertBlockContent, variant && styles[`${variant}BlockContent`])}>
        {mode !== AlertBlockMode.None && icon}
        <div className={cn(styles.alertBlockTitle, variant && styles[`${variant}BlockTitle`])}>{title}</div>
        {description && (
          <div className={cn(styles.alertBlockDescription, variant && styles[`${variant}BlockDescription`])}>
            {description}
          </div>
        )}
      </div>
      {link && (
        <NextLink href={link} passHref>
          <Button color='primary' variant='outlined' size='small' className={styles.errorBlockBtn}>
            {buttonText}
          </Button>
        </NextLink>
      )}
    </div>
  );
};

export default AlertBlock;

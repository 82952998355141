import React from 'react';
import styles from './AccountSidebar.module.scss';
import cn from 'classnames';
import { Sidebar } from '@components/Account/Sidebar';

export interface AccountSidebarProps {
  className?: string;
}

export const AccountSidebar: React.FC<AccountSidebarProps> = ({ className }) => {
  return <Sidebar className={cn(styles.accountSidebar, className)} />;
};

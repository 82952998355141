import React, { PropsWithChildren } from 'react';
import styles from './AccountWrapper.module.scss';
import Typography from '@mui/material/Typography';

export const AccountWrapper: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({ children }) => {
  return (
    <Typography component='div' variant='body2' className={styles.accountWrapper}>
      {children}
    </Typography>
  );
};

import React from 'react';
import styles from './Avatar.module.scss';
import { MemberIcon } from 'theme/icons';
import { AvatarProps } from './Avatar.interface';
import cn from 'classnames';

export function Avatar({ profilePhotoUrl, size, Initials, className }: AvatarProps): JSX.Element {
  return (
    <div className={className}>
      {profilePhotoUrl ? (
        <img
          width='120'
          src={profilePhotoUrl}
          className={cn(styles.accountSidebarAvatarImg, {
            [styles.accountSidebarAvatarImgSizeSmall]: size === 'small',
            [styles.accountDialogEditPlanMemberIcon]: size === 'medium',
          })}
        />
      ) : (
        <>
          {Initials ? (
            <div
              className={cn(styles.Initials, {
                [styles.InitialsSmall]: size === 'small',
                [styles.InitialsMedium]: size === 'medium',
              })}
            >
              {Initials}
            </div>
          ) : (
            <MemberIcon
              className={cn(styles.accountSidebarAvatarIcon, {
                [styles.accountSidebarAvatarIconSizeSmall]: size === 'small',
                [styles.accountDialogEditPlanMemberIcon]: size === 'medium',
              })}
            />
          )}
        </>
      )}
    </div>
  );
}

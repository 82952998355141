import React, { PropsWithChildren } from 'react';
import styles from './AccountContentInner.module.scss';

export const AccountContentInner: React.FC<PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
}) => {
  return <div className={styles.accountContentInner}>{children}</div>;
};

export default AccountContentInner;

export enum AlertBlockVariant {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum AlertBlockMode {
  None = 'none',
  Thin = 'thin',
}

import Head from 'next/head';
import React, { useContext, useEffect, useState } from 'react';
import { LayoutType } from 'types/GlobalTypes';
import { useRouter } from 'next/router';
import { UnauthenticatedLayout } from 'components/HomeLayout';
import AppshellContext from '@inc/AppshellContext';
import { ThemeProvider } from '@mui/material/styles';
import { themeDark } from '../../../theme';
import ConfirmExitDialog from 'components/Global/components/ConfirmExitDialog';
import Message from 'components/Global/components/Message';
import EventManager from 'services/EventManager';
import { pageTitle } from 'utils';
import { HeaderDefault } from '@components/Header/HeaderDefault';
import { HelpChat } from '@components/HelpChat/HelpChat';
import AlertBlock from '@ui/molecules/AlertBlock/AlertBlock';
import { AlertBlockMode, AlertBlockVariant } from '@ui/molecules/AlertBlock/AlertBlock.enum';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput, MemberSubscriptionInfoOutput } from 'services/api/client';
import { useCheckoutApi } from 'hooks/useCheckoutApi';
import { AccountSidebar } from '@ui/molecules/AccountSidebar/AccountSidebar';
import { AccountWrapper } from '@ui/atoms/AccountWrapper/AccountWrapper';
import { AccountContentWrapper } from '@ui/atoms/AccountContentWrapper/AccountContentWrapper';
import { AccountContentInner } from '@ui/atoms/AccountContentInner/AccountContentInner';
import { AccountContent } from '@ui/atoms/AccountContent/AccountContent';
import { BoxPage } from '@ui/atoms/BoxPage/BoxPage';
import { BoxPageTitle } from '@ui/atoms/BoxPageTitle/BoxPageTitle';
import { PageTitle } from '@ui/atoms/PageTitle/PageTitle';

export function AccountLayout({ children, title, headTitle, variant = 'default', access }: LayoutType): JSX.Element {
  const userContext = useContext(AppshellContext);
  const data = userContext?.user?.data;
  const { data: memberInfo } = useMemberApi<MemberInfoOutput>('memberInfo');
  const { data: info } = useCheckoutApi<MemberSubscriptionInfoOutput>('memberSubscriptionInfo');
  const [hiddenPastDueText, setHiddenPastDueText] = useState(false);
  const isPastDue = memberInfo && info && !!memberInfo?.payment_failed && info.expires < Date.now();
  const router = useRouter();
  const alertLink = '/payment-and-shipping';
  const showPaymentAlert = isPastDue && !hiddenPastDueText;
  useEffect(() => {
    if (title || headTitle) {
      EventManager.dispatch('page.viewed', headTitle ?? title);
    }
  }, []);

  useEffect(() => {
    if (memberInfo && access) {
      const allowed = typeof access.allowed === 'boolean' ? access.allowed : access.allowed(memberInfo);
      if (!allowed) {
        router.replace(access.redirect ?? '/profile');
      }
    }
  }, [memberInfo]);

  // Add body class for account pages.
  useEffect(() => {
    document.body.classList.add('page-account');
    return () => {
      document.body.classList.remove('page-account');
    };
  }, []);
  return (
    <>
      <Head>
        <title>{pageTitle(headTitle ?? title ?? 'User Account')}</title>
      </Head>
      {data ? (
        <ThemeProvider theme={themeDark}>
          <HeaderDefault showMenu={variant === 'box' ? false : true} />
          {variant === 'default' ? (
            <AccountWrapper>
              {showPaymentAlert && (
                <AlertBlock
                  mode={AlertBlockMode.Thin}
                  variant={AlertBlockVariant.Error}
                  onClose={() => setHiddenPastDueText(true)}
                  description='Your recent payment failed. To avoid interruption in your benefits, please update your payment method.'
                  buttonText='Update Payment Method'
                  link={!router.pathname.includes(alertLink) ? alertLink : undefined}
                />
              )}
              <AccountContentWrapper>
                <AccountContentInner>
                  <AccountSidebar />
                  <AccountContent>
                    {title && <PageTitle hide='mobile'>{title}</PageTitle>}
                    <ConfirmExitDialog />
                    <Message />
                    {children}
                  </AccountContent>
                </AccountContentInner>
              </AccountContentWrapper>
            </AccountWrapper>
          ) : (
            <BoxPage>
              {title && <BoxPageTitle>{title}</BoxPageTitle>}
              {children}
            </BoxPage>
          )}
        </ThemeProvider>
      ) : (
        <UnauthenticatedLayout />
      )}
      <HelpChat />
    </>
  );
}
